<template>
  <dialogForm
    :option="option"
    ref="form"
    title="关联系统数据"
    btnType="text"
    btnText="点击设置"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="form"
    @opened="onOpen"
    @submit="onSubmit"
    @cancel="onCancel"
  >
    <template #customfieldIdList>
      <el-checkbox-group v-model="checkList">
        <el-checkbox v-for="(item, index) in customfieldIdList" :label="item.id" :key="index">{{
          item.fieldName
        }}</el-checkbox>
      </el-checkbox-group>
    </template>
  </dialogForm>
</template>

<script>
import { requireFun } from '@/utils'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    data: Object
  },

  computed: {
    ...mapGetters(['customfieldIdList'])
  },

  created() {
    if (this.data?.customfieldIdList) this.$set(this.form, 'customfieldIdList', this.data.customfieldIdList)
  },

  data() {
    return {
      form: {},
      checkList: [],
      customfieldIdListCopy: [],
      option: {
        menuAlign: 'left',
        searchSize: 'small',
        labelPosition: 'left',
        column: [
          {
            label: '变量选择',
            // type: 'checkbox',
            prop: 'customfieldIdList',
            slot: true,
            span: 24,
            labelWidth: '90',
            rules: [
              {
                required: true,
                message: requireFun('变量选择')
              }
            ]
          }
        ]
      }
    }
  },

  watch: {
    checkList(n) {
      this.form.customfieldIdList = n
    }
  },

  methods: {
    onSubmit(data, done) {
      //this.customfieldIdListCopy = cloneDeep(this.form.customfieldIdList)
      console.log('customfieldIdListCopy', this.checkList)

      this.$emit('success', 'customfieldIdList', this.checkList.join(','))
      done()
    },

    onCancel() {
      //this.checkList = cloneDeep(this.customfieldIdListCopy)
    },

    onOpen() {
      this.$refs.form.clearValidate()
      if (Object.keys(this.data).length)
        this.checkList = this.data.customfieldIdList.split(',').map((id) => parseInt(id)).filter(Boolean)
    }
  }
}
</script>

<style lang="scss" scoped></style>
