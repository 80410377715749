var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"app-container"},[_c('breadCrumb'),_c('BaseForm1',{ref:"form",staticClass:"mt20",attrs:{"option":_vm.isAliExpress},scopedSlots:_vm._u([{key:"isSetDefaultCode",fn:function(){return [_c('el-radio-group',{model:{value:(_vm.form['isSetDefaultCode']),callback:function ($$v) {_vm.$set(_vm.form, 'isSetDefaultCode', $$v)},expression:"form['isSetDefaultCode']"}},_vm._l((_vm.radioList),function(ref){
var label = ref.label;
var value = ref.value;
return _c('el-radio',{key:label,attrs:{"label":label}},[_vm._v(_vm._s(value))])}),1),(_vm.show)?_c('setSysTemDialog',{ref:"systemRef",staticClass:"ml20",attrs:{"data":_vm.data},on:{"success":_vm.setData}}):_vm._e()]},proxy:true},{key:"isAllowSystemCode",fn:function(){return [_c('el-radio-group',{model:{value:(_vm.form['isAllowSystemCode']),callback:function ($$v) {_vm.$set(_vm.form, 'isAllowSystemCode', $$v)},expression:"form['isAllowSystemCode']"}},_vm._l((_vm.radioList),function(ref){
var label = ref.label;
var value = ref.value;
return _c('el-radio',{key:label,attrs:{"label":label}},[_vm._v(_vm._s(value))])}),1),(_vm.form['isAllowSystemCode'] == _vm.set)?_c('setInsertDialog',{ref:"insertRef",staticClass:"ml20",attrs:{"data":_vm.data},on:{"success":_vm.setData}}):_vm._e()]},proxy:true},{key:"isNeedDefaultValue",fn:function(){return [_c('el-radio-group',{model:{value:(_vm.form['isNeedDefaultValue']),callback:function ($$v) {_vm.$set(_vm.form, 'isNeedDefaultValue', $$v)},expression:"form['isNeedDefaultValue']"}},_vm._l((_vm.radioList),function(ref){
var label = ref.label;
var value = ref.value;
return _c('el-radio',{key:label,attrs:{"label":label}},[_vm._v(" "+_vm._s(value)+" ")])}),1)]},proxy:true},{key:"customDefaultValue",fn:function(){return [_c('div',{staticClass:"flex-wrap"},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入预设值","clearable":""},model:{value:(_vm.form['customDefaultValue']),callback:function ($$v) {_vm.$set(_vm.form, 'customDefaultValue', $$v)},expression:"form['customDefaultValue']"}}),(_vm.form['isNeedDefaultValue'] == _vm.set)?_c('setAttributeDialog',{ref:"attributeRef",staticClass:"ml20",attrs:{"data":_vm.data},on:{"insertHandler":_vm.insertHandler}}):_vm._e()],1)]},proxy:true},{key:"valueMaxLength",fn:function(){return [_c('el-input-number',{attrs:{"precision":0,"step":1,"min":1,"label":"请输入最大长度"},model:{value:(_vm.form.valueMaxLength),callback:function ($$v) {_vm.$set(_vm.form, "valueMaxLength", $$v)},expression:"form.valueMaxLength"}})]},proxy:true},{key:"valueRange",fn:function(ref){
var column = ref.column;
return [_c('rangeInput',{ref:"rangeInput",attrs:{"prop":column.prop,"minProp":column.minProp,"maxProp":column.maxProp,"rules":column.valueRangeRules},model:{value:(_vm.form.valueRange),callback:function ($$v) {_vm.$set(_vm.form, "valueRange", $$v)},expression:"form.valueRange"}})]}}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('div',{staticClass:"button-group"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.clickHandler}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.back}},[_vm._v("取消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }