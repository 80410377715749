import { apiFactory } from '@/utils/constant'

const fieldApi = {
  list: '/systemService/fieldManagement/list',
  update: '/systemService/fieldManagement/update',
  add: '/systemService/fieldManagement/create',
  del: '/systemService/fieldManagement/deleted',
  detail: '/systemService/fieldManagement/detail',
  getAliColumnList:'/systemService/aliColumn/getAliColumnList', //获取速卖通字段列表
  createAliColumnMap:'/systemService/aliColumn/createAliColumnMap', //新增速卖通字段
  updateAliColumnMap:'/systemService/aliColumn/updateAliColumnMap', //更新速卖通字段
  deleteAliColumnMap:'/systemService/aliColumn/deleteAliColumnMap', //删除速卖通字段
  aliColumnMapDetail:'/systemService/aliColumn/aliColumnMapDetail' //速卖通编辑详情
}

for (const key in fieldApi) {
  fieldApi[key] = apiFactory(fieldApi[key])
}

export default fieldApi
