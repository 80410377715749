<script>
export default {
  functional: true,
  render(h, { parent }) {
    const isEdit = !!parent.$route.query.id
    const title = isEdit ? '编辑系统模板' : '新增系统模板'

    return h(
      'div',
      {
        class: 'bread-crumb',
        on: {
          click: () => {
            parent.$router.back()
          }
        }
      },
      [h('i', { class: 'el-icon-d-arrow-left' }), h('span', title)]
    )
  }
}
</script>

<style lang="scss" scoped>
.bread-crumb {
  padding: 15px;
  background: #fff;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
}
</style>
