<template>
  <div class="range-input flex-middle">
    <el-form-item :ref="minProp" :prop="minFormItemProp" :rules="rangeFormRules[minProp]" :error="error">
      <el-input
        v-model="rangeForm[minProp]"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="'请输入最小' + (label || '值')"
        @keyup.enter.native="searchChange"
      />
    </el-form-item>
    <slot name="separator"><span class="mlr10">-</span></slot>
    <el-form-item :ref="maxProp" :prop="maxFormItemProp" :rules="rangeFormRules[maxProp]" :error="error">
      <el-input
        v-model="rangeForm[maxProp]"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="'请输入最大' + (label || '值')"
        @keyup.enter.native="searchChange"
      />
    </el-form-item>
    <slot name="suffix"></slot>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import { validatenull } from '@/components/avue/utils/validate'
import { isInteger, validNumber, isNumber } from '@/utils'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }, 
    label: String,
    prop: String,
    minProp: {
      type: String,
      default: 'min'
    },
    maxProp: {
      type: String,
      default: 'max'
    },
    rules: {
      type: Array|Object
    },
    numberType: {
      type: String,
      default: 'integer'
    },
    readonly: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      rangeForm: {},
      error: ''
    }
  },
  computed: {
    rangeFormRules({ numberType, rules, minProp, maxProp }) {
      if (!numberType) return {}
      
      // console.log('rangeFormRules')
      rules = [].concat(...[rules].filter(Boolean))

      rules.push({
        validator: {
          integer: isInteger
        }[numberType] || validNumber
      })
      
      let minRules = deepClone(rules)
      minRules.push({
        validator: (rule, value, callback) => {
          const { rangeForm: { [minProp]: min, [maxProp]: max } } = this
          // console.log(min, max, !min, !max, Number(min) < Number(max), this.validateFromOtherField)
          if (!isNumber(min) || !isNumber(max)) return callback()
          if (!min || !max || Number(min) < Number(max)) {
            this.validateFromOtherField = !this.validateFromOtherField
            if (this.validateFromOtherField) {
              this.validateField(this.maxProp)
            }
            return callback()
          }
          return callback(new Error('输入值不得大于最大阈值'))
        }
      })
      let maxRules = deepClone(rules)
      maxRules.push({
        validator: (rule, value, callback) => {
          const { rangeForm: { [minProp]: min, [maxProp]: max } } = this
          // console.log(max, min, !max, !min, Number(max) > Number(min), this.validateFromOtherField)
          if (!isNumber(min) || !isNumber(max)) return callback()
          if (!max || !min || Number(max) > Number(min)) {
            this.validateFromOtherField = !this.validateFromOtherField
            if (this.validateFromOtherField) {
              this.validateField(this.minProp)
            }
            return callback()
          }
          return callback(new Error('输入值不得小于最小阈值'))
        }
      })
      return {
        [minProp]: minRules,
        [maxProp]: maxRules
      }
    },
    minFormItemProp() {
      return [this.prop, this.minProp].filter(Boolean).join('.')
    },
    maxFormItemProp() {
      return [this.prop, this.maxProp].filter(Boolean).join('.')
    }
  },
  watch: {
    value: {
      handler(n) {
        // console.log(this.$attrs)
        this.rangeForm = n
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onchange() {
      this.$emit('change', this.rangeForm)
    },
    searchChange() {
      this.$emit('searchChange', this.rangeForm)
    },

    validateFields() {
      let { label, minProp, maxProp, rangeForm } = this
      let { [minProp]: minVal,[maxProp]: maxVal } = rangeForm
      let isMinValNull = validatenull(minVal)
      let isMaxValNull = validatenull(maxVal)
      if (
        !(
          (isMinValNull && isMaxValNull) ||
          (!isMinValNull && !isMaxValNull)
        )
      ) {
        return (label || '') + '应全填或不填'
      }
    },
    async validate() {
      this.error = ''
      let { label, minProp, maxProp, rangeForm } = this
      let { [minProp]: minVal,[maxProp]: maxVal } = rangeForm
      let isMinValNull = validatenull(minVal)
      let isMaxValNull = validatenull(maxVal)
      if (
        !(
          (isMinValNull && isMaxValNull) ||
          (!isMinValNull && !isMaxValNull)
        )
      ) {
        this.error = label + '应全填或不填'
        return false
      }
      return true
    },
    validateField(props, cb) {
      props = [].concat(props);
      for (const prop of props) {
        this.$refs[prop].validate('', cb)
      }
    },
    resetFields() {
      for (const prop of [this.minProp, this.maxProp]) {
        this.$refs[prop].resetField()
      }
    },
    clearValidate() {
      for (const prop of [this.minProp, this.maxProp]) {
        this.$refs[prop].clearValidate()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-form-item {
  margin-bottom: 0;
}
.mlr10 {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
